form {
    input,
    input:focus,
    input:hover,
    input:active,
    textarea,
    textarea:focus,
    textarea:hover,
    textarea:active {
        transition: all 0.33s ease-in-out;
        outline: none !important;
        width: 100%;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    input[type=text] {
        width: 100%;
        max-width: 290px;
        height: 48px;
        border: 2px solid $color-brand;
        background-color: $white;
        color: #1c1c1c !important;
        font-family: 'nimbus_sans_round_medium';
        font-size: 16px !important;
        padding:0 12px !important;
        line-height: 48px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    textarea {
        background-color: transparent;
        border: 2px solid $color-brand;
        padding: 4px 4px 4px 12px;
        color: rgba(255, 255, 255, 0.33);
        font-size: 16px;
        line-height: 26px !important;
        height: 144px;
        width: 100%;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: rgba(255, 255, 255, 1);
            border: 2px solid $color-brand3;
        }
    }

    input:not([type=submit]):not([type=button]) {
        transition: all 0.33s ease-in-out;
        height: 48px;
        background-color: $white;
        border: 2px solid $color-brand;
        color: #1c1c1c !important;
        padding: 0px 4px 0px 12px;
        font-size: 12px;
        font-family: 'nimbus_sans_round_bold';
        line-height: 48px;
        width: 100%;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: $color-brand;
            border: 2px solid $color-brand3;
            background: white;
        }
    }

    label.required {
        display: inline-block;
        position: relative;

        &:after {
            display: block;
            content: '*';
            color: $white;
            position: absolute;
            top: 0;
            right: -10px;
        }
    }
}

.gform_body {
    ul.gform_fields {
        margin: 24px 0;
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 24px;

            &:last-child {
                margin-top: 24px;
            }
        }

        .gfield_label {
            font-family: 'nimbus_sans_round_bold';
            text-transform: uppercase;
            letter-spacing: 1.4px;
            color: $white;
            position: absolute;
            padding: 4px 0 0 12px;
            opacity: 0;
        }

        .gfield {
            .ginput_container_text {
                input {
                    width: 100%;
                }
            }

            .ginput_container_textarea {
                textarea {
                    width: 100%;
                }
            }

            .ginput_container_email {
                width: 100%;
            }
        }

        .gfield_label {
            .gfield_required {
                color: $white;
            }
        }
    }

    .validation_message {
        color: $color-brand;
    }
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    transition: all 0.33s ease-in-out;
    color: #1c1c1c !important;
    padding: 0px 4px 0px 12px !important;
    font-size: 12px !important;
    font-family: 'nimbus_sans_round_bold' !important;
    font-weight: 200;
}

.gform_button {
    @extend .btn;
    background-color: $color-brand;
    display: inline-block;
    white-space: nowrap;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: auto;

    &:hover {
        text-decoration: none;
        width: auto;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        float: none;
    }
}

.gform_fields {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.gfield_visibility_hidden {
    display: none;
}

.gform_validation_container {
    display: none !important;
}

.gform_confirmation_message {
    font-family: 'nimbus_sans_round_regular';
    font-size: 12px;
}

.form__newsletter {
    position: relative;
    display: block;
    width: 100%;
    max-width: 290px;
    margin-top: 10px;

    .gform_heading {
        margin-bottom: 0;

        .gform_title {
            color: #1c1c1c;
            font-family: 'nimbus_sans_round_bold';
            font-size: 12px;
            letter-spacing: 3px;
            line-height: 16px;
            font-weight: normal;
            text-transform: uppercase;
            margin-bottom: 6px;
            margin-left: 2px;
        }
    }

    .gform_body {
        position: relative;
        display: inline-block;
        width: 100%;
        max-width: 290px;

        input,
        input:focus,
        input:hover,
        input:active {
            height: 32px !important;
            line-height: 32px !important;
        }

        .gform_fields {
            margin: 0;
            max-width: calc(100% - 90px);

            .gfield {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .gform_footer {
        display: inline-block;
        position: absolute;
        right: -16px;

        .gform_button {
            transition: all 0.33s ease-in-out;
            background-color: $color-brand;
            font-size: 12px;
            font-family: 'nimbus_sans_round_bold';
            color: $white !important;
            border: 1px solid $color-brand;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 1px;

            &:hover {
                transition: all 0.33s ease-in-out;
                background-color: $color-brand3;
                border: 1px solid $color-brand3;
                color: $color-brand !important;
            }
        }
    }

    .validation_error {
        transition: all 0.33s ease-in-out;
        font-family: 'nimbus_sans_round_bold';
        font-size: 12px;
        line-height: 16px;
        font-style: italic;
    }

    .validation_message {
        transition: all 0.33s ease-in-out;
        font-family: 'nimbus_sans_round_bold';
        font-size: 12px;
        line-height: 16px;
        font-style: italic;
    }
}

#contact-info {
    font-family: 'nimbus_sans_round_medium';
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: normal;
}

#contact-info .form__contact,
#contact-info .o-order-forms,
#catering-info .form__custom-catering,
#contact-info .form__order-thanksgiving {
    padding: 0 18%;
    padding-top: 10px;
    padding-top: 30px;
    padding-bottom: 50px;
    width: 100%;
    max-width: 100%;

    @media (max-width: 1366px) {
        padding: 20px 20px 30px;
    }

    @include media-breakpoint-down(md) {
        padding: 20px 0 30px;
    }

    input,
    input:focus,
    input:hover,
    input:active,
    textarea,
    textarea:focus,
    textarea:hover,
    textarea:active,
    select {
        width: 100%;
        max-width: 100%;
        color: $color-brand !important;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    .gform_fields {
        width: 100%;
        display: block;

        li {

            &:nth-child(2) {
                display: inline-block;
                width: 48%;
                margin-right: 4%;

                @media (max-width: 1112px) {
                    width: 100%;
                    margin-right: 0;
                }
            }

            &:nth-child(3) {
                width: 48%;
                display: inline-block;

                @media (max-width: 1112px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }

    .gfield {
        position: relative !important;
        margin-bottom: 56px;

        &:first-child {
            width: 100%;
            display: inline-block;
        }
    }

    .gfield_label, label {
        font-family: 'nimbus_sans_round_bold';
        text-transform: uppercase;
        letter-spacing: 1.4px;
        opacity: 1;
        color: $color-brand;
        font-size: 12px;
        //top: -30px;
        left: 0;
        padding-left: 2px;
        position: relative;
        display: block;
    }

    .ginput_container_name {
        .name_first {
            width: 48%;
            display: inline-block;
            margin-right: 3.48%;

            @media (max-width: 1112px) {
                width: 100% !important;
                display: block;
                margin-right: 0;
            }

            @include media-breakpoint-down(md) {
                width: 100% !important;
                display: block;
                margin-right: 0;
            }
        }

        .name_last {
            width: 48%;
            display: inline-block;

            @media (max-width: 1112px) {
                width: 100% !important;
                display: block;
            }

            @include media-breakpoint-down(md) {
                width: 100% !important;
                display: block;
            }
        }
    }

    .gform_button {
        @extend .btn;
        background-color: $color-brand !important;
        border: 2px solid $color-brand !important;
        display: inline-block;
        white-space: nowrap;
        user-select: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        width: 100%;
        color: $white !important;

        &:hover {
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;

            text-decoration: none;
            background-color: $color-brand3 !important;
            border: 2px solid $color-brand3 !important;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            float: none;
        }
    }

    .select-css {
        select {
            display: block;
            font-family: 'nimbus_sans_round_bold';
            text-transform: uppercase;
            letter-spacing: 1.4px;
            color: $color-brand;
            font-size: 12px;
            padding: .6em 1.4em .5em .8em;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            height: 46px;
            //margin: 0 0 56px 0;
            border: 0 solid transparent;
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            border-radius: 0 !important;
            appearance: none;
            background-color: #fff;
            //background-image: url(''), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
            background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
            background-repeat: no-repeat, repeat;
            background-position: right .7em top 50%, 0 0;
            background-size: .65em auto, 100%;
            -webkit-appearance:none;

            &::-ms-expand {
                display: none;
            }

            &:hover {
                border-color: $color-brand;
            }

            &:focus {
                border-color: $color-brand3;
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                box-shadow: 0 0 0 0 -moz-mac-focusring;
                color: $color-brand;
                outline: none;
            }

            /* Disabled styles */
            &:disabled,
            &[aria-disabled=true] {
                color: $color-brand;
                background-image: url(''), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
            }

            &:disabled:hover,
            &[aria-disabled=true] {
                border-color: $color-brand;
            }

            option {
                font-weight: normal;
            }
        }

        .ginput_container_select {
            border: 2px solid $color-brand;
            border-radius: 0 !important;
        }

        .gfield_select {
            border: 0px solid transparent;
            border-radius: 0 !important;
        }
    }

    /* Support for rtl text, explicit support for Arabic and Hebrew */
    *[dir="rtl"] .select-css select,
    :root:lang(ar) .select-css select,
    :root:lang(iw) .select-css select {
        background-position: left .7em top 50%, 0 0;
        padding: .6em .8em .5em 1.4em;
    }

}

#catering-info .form__custom-catering {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 40px;

    .gform_title {
        color: $color-brand;
        text-transform: uppercase;
        font-size: 48px;
        line-height: 50px;
        letter-spacing: 5px;
    }

    select {
        -webkit-appearance:none;
    }

    .gform_fields {
        .gfield {
            &:first-child {
                .gfield_label_before_complex {
                    display: none;
                }
            }
        }
    }

    .contact-names {
        .gfield_label {
            opacity: 0;
        }

        .name_first,
        .name_last {
            position: relative;
            width: 48%;
            display: inline-block;
        }

        .name_first {
            margin-right: 2.9%;
        }

        label {
            position: absolute;
            top: -26px;
        }
    }

}

#contact-info .form__order-thanksgiving {
    padding-top: 0 !important;

    .gfield_description {
        font-size: 0.7rem;
        font-style: italic;
    }

    .ginput_product_price_label {
        font-size: 1rem;
    }

    .pickup-date, .pickup-time {
        width: 50%;
        display: inline-block;

        .screen-reader-text {
            font-size: 0;
            color: $white;
            height: 0;
            overflow: hidden;
            display: none;
        }
    }

    .pickup-date{
        padding-right: 10px;
    }

    .pickup-time {
        padding-left: 10px;

        .gfield_label {
            left: 10px;
        }
    }

    .gfield_price {
        width: 50%;
        max-width: 50%;
        display: inline-block;

        @include media-breakpoint-down(sm) {
            width: 100% !important;
            max-width: 100%;
        }

        @include media-breakpoint-down(md) {
            width: 100% !important;
            max-width: 100%;
            display: block;
        }

        .ginput_container_singleproduct {
            max-width: 100%;
        }

        .ginput_quantity_label {
            padding-left: 20px;
        }
        .ginput_product_price_label,
        .ginput_product_price,
        .ginput_quantity_label {
            font-size: 0.7rem;
        }

        .ginput_quantity {
            width: 40px;
            max-width: 40px;
            margin-right: 20px;
            font-size: 0.7rem;
            line-height: 40px;
            height: 40px;
        }
    }
}

#contact-info .form__order-wines {
    padding-top: 0 !important;

    .gform_fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
        list-style: none;

        .gfield {

            &.html_description {
                width: 100%;
                margin-bottom: 16px;
                margin-top: 30px;

                h3{
                    font-size: 1.4rem !important;
                }
            }

            &.custom-selection {
                width: 31%;
            }

            &.total-budget {
                width: 31%;
            }

            &.select-quantity{
                margin-top: -30px;

            }

            &.gfield_price {
                width: 48%;
                max-width: 48%;

                @include media-breakpoint-down(sm) {
                    width: 100% !important;
                    max-width: 100%;
                }

                @include media-breakpoint-down(md) {
                    width: 100% !important;
                    max-width: 100%;
                    display: block;
                }

                .ginput_container_singleproduct {
                    max-width: 100%;
                }

                .ginput_quantity_label {
                    padding-left: 20px;
                }

                .ginput_product_price_label,
                .ginput_product_price,
                .ginput_quantity_label {
                    font-size: 0.7rem;
                }

                .ginput_quantity {
                    width: 40px;
                    max-width: 40px;
                    margin-right: 20px;
                    font-size: 0.7rem;
                    line-height: 40px;
                    height: 40px;
                }
            }
        }
    }

    .gfield_description {
        font-size: 0.7rem;
        font-style: italic;
    }

    .ginput_product_price_label {
        font-size: 1rem;
    }

    .pickup-date,
    .pickup-time {
        width: 50%;
        display: inline-block;

        .screen-reader-text {
            font-size: 0;
            color: $white;
            height: 0;
            overflow: hidden;
            display: none;
        }
    }

    .pickup-date {
        padding-right: 10px;
    }

    .pickup-time {
        padding-left: 10px;

        .gfield_label {
            left: 10px;
        }
    }

}

.grecaptcha-badge {
	display: none;
}

#ui-datepicker-div {
    background: $white;
    padding: 20px;
    font-family: 'nimbus_sans_round_regular' !important;

    @include media-breakpoint-down(sm) {
        width: 80% !important;
    }

    .ui-datepicker-header {
            font-family: 'nimbus_sans_round_regular' !important;

        .ui-datepicker-next{
            font-family: 'nimbus_sans_round_regular' !important;
            position: absolute;
            right: 20px;
        }
    }

    .ui-datepicker-calendar {
        width: 100%;
    }
}
