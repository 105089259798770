html {
    height: 100%;
    font-size: ($base-font-size / 20px) * 1em; /* [1] */
    //line-height: $base-line-height / $base-font-size; /* [1] */
    margin: 0 !important;
    padding: 0;
    color: $color-text;
    font-family: 'nimbus_sans_round_regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: #1a1a1a;
    //background: $color-brand url('assets/images/tile.svg') repeat;
    background: $color-brand;
    background-size: 10%;

    @include media-breakpoint-down(sm) {
        background-size: 34%;

    }

    &.logged-in {
        padding-top: 32px;
    }
}

.tar {
    text-align: right;
}
