.o-page-footer {
    position: relative;
    padding-left: $nav-width;
    width:100%;
    margin: auto auto 0 auto;
    //background: $color-brand2 url('assets/images/Bottom-Pattern.svg') repeat;
    //background-size: 50%;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: $color-brand2 url('assets/images/Bottom-Pattern.svg') top left repeat;
        background-size: 50%;
        width: 100%;
        height: 100%;
        opacity: 0.065;
        z-index: -1;
    }


    @include media-breakpoint-down(sm) {
        padding-left: 0;
    }

    .o-page-footer_top {
        padding: 12px 0 11px;

        .row {

            @include media-breakpoint-down(sm) {
                //display: flex 0;
                //flex-shrink: 0;
                //flex-direction: row-reverse;
            }

            .col-sm-12 {
                @include media-breakpoint-down(sm) {
                    display: flex 0;
                    //flex-shrink: 0;
                    width: 100%;
                }
            }
        }

        .tar {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .o-page-footer_link--logo {

            @include media-breakpoint-down(sm) {
                display: block;
                margin-bottom: 24px;
            }

            .o-page-footer_link--logo--image {
                max-width: 60%;
            }
        }
    }

    .o-page-footer_menu {
        display: block;
        color: $white;
        text-align: left;
        margin: 0 ;

        .nav__links {
            width: 100%;
            display: inline-block;
            list-style-type: none;
            margin: 6px 0 0 0;
            padding: 0;

            @include media-breakpoint-down(sm) {
                display: block;
                margin-bottom: 30px;
            }

            li {
                display: inline-block;
                margin: 0;
                padding: 0 10px;

                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                    display: block;
                }

                a {
                    transition: all 0.33s ease-in-out;
                    position: relative;
                    display: block;
                    color: #e6e6e6;
                    line-height: 16px;
                    padding: 0;
                    font-family: 'nimbus_sans_round_medium';
                    font-size: 16px;

                    &:after {
                        transition: all 0.33s ease-in-out;
                        content: '';
                        position: absolute;
                        display: block;
                        bottom: -2%;
                        left: 0;
                        height: 2px;
                        width: 0;
                        //max-width: 40px;
                        background: $color-brand3;
                        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
                    }

                    @include media-breakpoint-down(sm) {
                        text-align: center;
                        line-height: 20px;
                    }

                    &:hover {
                        transition: all 0.33s ease-in-out;
                        color: $white;
                        text-decoration: none;

                        &:after {
                            transition: all 0.33s ease-in-out;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .o-page-footer_bottom {
        padding-top: 1px;
        background: $color-brand1;
        text-align: center;
    }

    .copyright {
        display: block;
        margin: 0;
        padding: 10px 0 6px;
        text-align: center;
        color: #e1e1e1;
        line-height: 14px;
        font-family: 'nimbus_sans_round_medium';
        font-size: 14px;

        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-top: 0;
            line-height: 1;
            margin-bottom: 95px;
            padding: 30px 0;
        }

        a {
            transition: all 0.33s ease-in-out;
            color: #e1e1e1;

            &:hover {
                transition: all 0.33s ease-in-out;
                color: $white-darken;
                text-decoration: none;
            }
        }
    }
}
