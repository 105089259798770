.c-avatar {
    display: block;
    border-radius: 100%;
    width:  64px;
    height: 64px;
}

.c-avatar--small {
    width:  32px;
    height: 32px;
}

.c-avatar--large {
    width:  128px;
    height: 128px;
}
