h1 {
    color: $color-brand;
    font-family: 'nimbus_sans_round_bold';
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    color: $color-brand;

    @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 36px;
    }
}

h2 {
    //@include font-size(28px);
    color: $color-brand;
    font-family: 'nimbus_sans_round_bold';
    font-size: 48px;
    line-height: 50px;
    margin-bottom: 8px;

    a {
        color: $color-brand;

        &:hover {
            text-decoration: none;
        }
    }

    @include media-breakpoint-down(md) {
        @include font-size(20px);
    }
}

h3 {
    @include font-size(16px);
    font-family: 'nimbus_sans_round_bold';
    color: $color-brand;
}

h4 {
    @include font-size(16px);
    font-family: 'nimbus_sans_round_bold';
    margin-bottom: 9px;
}

h5 {
    font-family: 'nimbus_sans_round_bold';
    @include font-size(18px);
    margin-bottom: 2px;
}

h6 {
    font-family: 'nimbus_sans_round_bold';
    @include font-size(16px);
}
