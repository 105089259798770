@mixin font-size($font-size, $line-height: auto) {
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;

    @if $line-height == auto {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }

    @else {

        @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height;
        }

        @elseif ($line-height != none and $line-height != false) {
            @warn "D’oh! ‘#{$line-height}’ is not a valid value for `line-height`."
        }

    }

}


@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
