a {
    text-decoration: none;
    color: $color-link;

    &:hover,
    &:focus {
        color: $color-link-hover;
        text-decoration: none;
    }

}

.phone, .map {
    font-weight: 900;
    letter-spacing: 3px;
}
