.btn {
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 14px 30px 8px;
    text-decoration: none;
    background-color: transparent;
    color: $white;
    border-radius: 0;
    border: 2px solid $color-brand3;
    transition: background-color 0.2s;
    font-family: 'nimbus_sans_round_regular';
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        width: 100%;
        display: block;
    }

    &:not([href]):not([tabindex]) {
        color: $color-brand3;
    }

    &.first {
        margin-right: 20px;

        @include media-breakpoint-down(sm) {
            margin-right: 0;
            margin-bottom: 18px;
        }
    }

    &.alt {
        transition: all 0.5s ease-in-out;
        //background-color: $color-brand;
        border: 2px solid $color-brand3;
        color: $color-brand;

        &:before {
            transition: 0.5s all ease;
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            bottom: 0;
            opacity: 0;
            background-color: $color-brand3;
            z-index: -2;
        }

        &:hover,
        &:focus {
            transition: all 0.5s ease-in-out;
            border: 2px solid $color-brand3;
            color: $white !important;

             &:before {
                 transition: 0.5s all ease;
                 left: 0;
                 right: 0;
                 opacity: 1;
             }
        }

        &.b {
            transition: all 0.5s ease-in-out;
            background-color: transparent;
            border: 2px solid $color-brand;
            color: $color-brand;

            &:before {
                background-color: $color-brand3;
            }

            &:hover,
            &:focus {
                transition: all 0.5s ease-in-out;
                background-color: $color-brand3;
                border: 2px solid $color-brand3;
                color: $white;

                &:before {
                    transition: 0.5s all ease;
                    left: 0;
                    right: 0;
                    opacity: 1;
                }
            }
        }
    }

    &:before {
        transition: 0.5s all ease;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        bottom: 0;
        opacity: 0;
        background-color: $color-brand3;
        z-index: -2;
    }

    &:hover,
    &:focus {
        transition: all 0.5s ease-in-out;
        border: 2px solid $color-brand3;
        color: $white;

         &:before {
             transition: 0.5s all ease;
             left: 0;
             right: 0;
             opacity: 1;
         }
    }

    &.altB {

        &:before {
            background-color: $color-brand;
        }

        &:hover,
        &:focus {
            border: 2px solid $color-brand;
            color: $white;

            &:before {
                transition: 0.5s all ease;
                left: 0;
                right: 0;
                opacity: 1;
            }
        }
    }

}
