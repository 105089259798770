$nav-width: 300px;

.o-page-content {
    transition: all 0.33 ease-in-out;
    position: relative;
    flex: 1;
    color: $color-text;
    width: 100%;
    padding-left: $nav-width;

    @include media-breakpoint-down(sm) {
        transition: all 0.33 ease-in-out;
        padding-left: 0;
        margin-top: 64px;
    }

    &.article {
        background: $white;
    }
}

.o-page-primary-imagery {
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    @include media-breakpoint-down(sm) {
        height: calc(100vh - 64px);
        padding: 0;
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0.5;
    }

    .o-page-primary-image {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc( 100% + 300px);
        height: 100vh;
        z-index: -1;
        // transform: scale(1, 1);
        // -webkit-animation: bummer 3s ease-in;
        // animation: bummer 3s ease-in;
        // -webkit-animation-fill-mode: forwards;
        // animation-fill-mode: forwards;
        background-size: cover;
    }

    .pageVideo {
        position: absolute;
        z-index: 0;
        top: 0;
        height: 100vh;

        @include media-breakpoint-down(sm) {
            left: -100%;
        }
    }

    .o-page-primary-imagery__inner {
        position: relative;
        z-index: 99;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 10%;

        @include media-breakpoint-down(sm) {
            padding: 0 20px;
            margin-top: -70px;
        }

        .o-page-primary-imagery__inner-wrapper {
            //display: block;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        h1 {
            position: relative;
            color: $white;
            text-transform: uppercase;
            margin-bottom: 24px;
            font-size: 60px;
            line-height: 64px;

            &:after {
                content: '';
                position: absolute;
                display: block;
                bottom: -2%;
                left: 0;
                height: 4px;
                width: 100%;
                max-width: 40px;
                background: $color-brand3;
                box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);

                @include media-breakpoint-down(sm) {
                    left: 50%;
                    margin-left: -30px;
                    max-width: 60px;
                }
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
                font-size: 22px;
                line-height: 1.25;
                letter-spacing: 2px;
            }
        }

        h3 {
            font-size: 30px;
            font-family: 'nimbus_sans_round_bold';
            line-height: 34px;
            color: $white;

            @include media-breakpoint-down(sm) {
                font-size: 0.7rem;
                line-height: 1.25;
            }
        }

        p {
            color: $white;
            font-family: 'nimbus_sans_round_medium';
            font-size: 26px;
            line-height: 36px;
            font-weight: 100;
            margin-bottom: 28px;
            letter-spacing: 1px;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 1.25;
                margin-bottom: 18px;
            }
        }

    }
}

//for sliders
.primary-imagery {
    &.slides-A {
        background: $white;

        .slider {
            margin-left: 10%;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
    }

    &.slides-B {
        background: transparent;

        @include media-breakpoint-down(sm) {
            margin-top: 26px;
            margin-bottom: 20px
        }

        .slider {
            margin-right: 10%;

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
    }

    &.gallery-A {
        margin-top: 92px;
        @include media-breakpoint-down(md) {
            margin-top: 50px;
        }
    }
    &.gallery-B, &.gallery-C {
        margin-top: 300px;
        @include media-breakpoint-down(md) {
            margin-top: 150px;
        }
    }

    &.gallery-A, &.gallery-B, &.gallery-C {
        background: transparent;
        height: 750px;

        .slider {
            height: 100%;
            .slick-list {
                height: 100%;
                .slick-track {
                    height: 100%;
                    .slide {
                        height: 100%;
                        margin: 0 0.25rem;
                        .featured-image {
                            height: 100%;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                }
                .slick-prev {
                    left: 0.5rem;
                    z-index: 1000;
                }
                .slick-next {
                    right: 0.5rem;
                    z-index: 1000;
                }
            } 
        }

        @include media-breakpoint-down(md) {
            height: 400px;
        }
    }
}

.featured-image {
    width: 100%;
    height: auto;
}

.o-cta-section {
    position: relative;
    width: 100%;
    min-height: 410px;
    font-size: 0;
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: $white;
    font-family: 'nimbus_sans_round_medium';
    font-size: 18px;
    line-height: 32px;

    @include media-breakpoint-down(sm) {
        width: 100%;
        display: block !important;
        padding: 0 20px;
    }

    &.our-story{
        z-index: 0;

        .ourstory-img {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            display: block;
            bottom: -39%;
            background: url('assets/images/turnip.svg') no-repeat;
            background-size: contain;
            background-position: right 0 bottom 0;
        }

        h1 {
            color: $white;
            text-transform: uppercase;
        }

        h3 {
           font-size: 30px;
           font-family: 'argent_cfregular';
           line-height: 34px;
           color: $white;
        }

        p {
            color: $white;
        }
    }

    &.contact {
        min-height: auto;
        padding-top: 50px;
        padding-bottom: 40px;
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(sm) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
        }

        h1 {
            color: $white;
            text-transform: uppercase;
        }
    }

    &.alt {
        background: $white;
        color: $color-brand;
        font-size: 20px;
        line-height: 28px;

        h2 {
            color: $color-brand;
        }

        p {
            color: $color-brand;
        }

        .o-cta-col_inner {
            color: $color-brand;

            h2 {
                color: $color-brand;
            }

            p {
                color: $color-brand;
            }

        }
    }

    h2 {
        color: $white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 48px;
        font-family: 'nimbus_sans_round_bold';
        letter-spacing: 1px;
    }

    h3 {
        font-size: 30px;
        font-family: 'nimbus_sans_round_medium';
        line-height: 34px;
        color: $white;
    }

    .o-cta-col_inner {
        width: 100%;
        color: $white;
        font-family: 'nimbus_sans_round_medium';
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;

        &.home {
            text-align: left;

            @include media-breakpoint-down(sm) {
                padding-right: 0;
                text-align: center;
            }

            h2 {
                margin-top: 30px;
            }
        }

        @include media-breakpoint-down(sm) {
            padding-right: 0;
        }

        h1 {
            color: $white;
            text-transform: uppercase;
        }

        h2 {
            color: $white;
            font-size: 48px;
            line-height: 50px;
            font-weight: 700;
            margin-top: 12px;

            @include media-breakpoint-down(sm) {
                font-size: 26px;
                line-height: 1.5rem;
            }
        }

        h3 {
            font-size: 30px;
            line-height: 34px;
            color: $white;
        }

        p {
            color: $white;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
        }

        .btn {
            margin: 12px 0 20px 0;

            &.first {
                margin: 12px 20px 20px 0;

                @include media-breakpoint-down(sm) {
                    margin: 12px 0 20px 0;
                }
            }
        }
    }

    .o-cta-section__image {
        position: relative;
        display: block;
        z-index: 1;
        width: 100%;
        top: 26px;

        @include media-breakpoint-down(sm) {
            top: -20px;
            margin: 45px auto;
            width: 80%;
        }

        &:after {
            content:'';
            position: absolute;
            display: block;
            z-index: 1;
            top: -5%;
            bottom: 0;
            left: -5%;
            right: 0;
            width: 116%;
            height: 116%;
            background: url('assets/images/gold-frame.png') center center no-repeat;
            background-size: cover;

            @include media-breakpoint-down(sm) {
                left: -8%;
            }
        }

        img {
            position: relative;
            z-index: 0;
            display: block;;
            width: 100%;
            height: auto;
            padding: 2%;
        }
    }
}

.o-page-article-content {
    padding: 0 22%;
    padding-top: 120px;
    padding-bottom: 100px;
    text-align: center;
    font-family: 'nimbus_sans_round_medium';
    font-size: 18px;

    @include media-breakpoint-down(sm) {
        padding: 10%;
    }

    .o-page-article-content-inner {
        //padding: 0 22%;

        @include media-breakpoint-down(sm) {
            //padding: 0 10px;
        }
    }

    h1, h2 {
        text-transform: uppercase;
    }

}

.o-multiboxes {
   .row {

        &:nth-child(even) {
            flex-direction: row-reverse;

            @include media-breakpoint-down(sm) {
                flex-direction: row;
            }
        }
    }
}

.o-multibox {
    min-height: 500px;
    background: $color-brand;
    color: $white;
    display:flex;
    align-items:center;
    justify-content: center;

    .o-multibox_inner {
        display: block;
        width: 100%;
        padding: 0 22%;
        padding-top: 80px;
        padding-bottom: 60px;
        color: $white;
        font-family: 'nimbus_sans_round_medium';
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;

        @include media-breakpoint-down(sm) {
            padding: 10%;
        }

        h2 {
            color: $white;

            @include media-breakpoint-down(sm) {
                font-size: 2rem;
                line-height: 1;
            }
        }
    }
}


.o-menu-item__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .o-menu-item {
        flex: 0 0 31.2%;
        margin-bottom: 32px;
        /* (100-32*3)/2 */
        /* Style for "Shadow" */
        box-shadow: 0 8px 16px rgba(48, 49, 51, 0.1),
            0 0 1px rgba(48, 49, 51, 0.05);
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0);
        overflow: hidden;

        .o-menu-item-text {
            padding: 22px;
            font-size: 16px;
            line-height: 28px;
            font-family: "freight-sans-pro";
            font-weight: 400;

            h4 {
                font-size: 21px;
                line-height: 32px;
                font-family: 'argent_cfbold';
                letter-spacing: 2px;
                text-transform: uppercase;
            }

            p {
                font-size: 16px;
                line-height: 28px;
                font-family: "freight-sans-pro";
                font-weight: 400;
            }
            .price {
                font-size: 24px;
                line-height: 36px;
                font-family: 'argent_cfbold';
            }
        }

    }
}

.contact {

    #contact-info {
        position: relative;
        background: $white;

        &.contact-page-additional-info {

            @include media-breakpoint-down(md) {
                padding: 20px;
            }

            .contact-additional-info {
                padding: 20px;

                @media (max-width: 1366px) {
                    padding: 20px;
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: url('assets/images/Bottom-Pattern.svg') top left repeat;
            background-size: 50%;
            width: 100%;
            height: 100%;
            opacity: 1.065;
            z-index: -1;
        }
    }



    .contact-page-content {
        background: url('assets/images/wood-texture.png') top right no-repeat;
    }
}

.o-contact-map {
    margin: 0;
    padding: 0;
    height: 440px;
    overflow: hidden;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.o-pagination {
    .page-numbers {
        padding: 10px;
        color: $color-brand;

        &:hover {
            text-decoration: none;
            color: $color-brand2;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.current {
            color: $white;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                color: $color-brand2;
            }
        }

        &.next,
        &.prev {
            color: $color-brand2;
            font-weight: bold;

            &:hover {
                text-decoration: none;
                color: $color-brand;
            }
        }
    }
}

.no-mobile {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.yes-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

/* Animation */
@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

@keyframes bummer {
    100% {
        transform: scale(1.1, 1.1);
    }
}

.sb_instagram_header,
.sbi_follow_btn {
    display: none !important;
}

.o-instagram-feed {
    margin: auto;
    text-align: center;
    position: relative;
    width: 100%;
    padding: 92px 0;
    background: $white;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    h2 {
        margin-top: 0;

        a{
            color: $color-brand !important;
        }
    }
}

#sbi_images {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.sbi_item {
    padding: 10px;
    margin: 2px;
    display: inline-block;
    vertical-align: top;
}

.sbi_info {
    display: none !important;
}

.sbi-owl-stage-outer {
    margin: 0 50px;

    @include media-breakpoint-down(sm) {
        margin: 0 10px;
    }
}

.sbi-owl-nav {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

#parallaxOS3 {
    @include media-breakpoint-down(sm) {
        min-height: 250px;
    }
}

#os_slider.slider {
    width: 100%;
    margin: 0;

    .slide {
        display: flex;
        flex: 1;
        justify-content:center;
        align-items: center;
        text-align: center;
        min-height: 160px;

        a {
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex: 1;
            justify-content:center;
            text-align: center;

            img {
                display: block;
                max-width: 160px;
                height: auto;
                margin: 0 auto;
            }

            h3{
                position: relative;
                word-wrap: break-word;
                text-align: center;
                margin: -14px auto 0;
                //left: 50%;
                //transform: translate(-50%, -50%);
                color: $color-brand;
            }
        }


    }
}

#slides_A_market {
    .slick-dots {
        width: 100%;
        margin: 0;
        padding-left: calc(10% - 16px);
        padding-right: calc(10% - 16px);
        //z-index: 999;
        top: -220px;

        li {
            margin: 0;
            padding: 16px;
            width: 100%;
            max-width: 16.666667%;

            span {
                display: block;
                height: 150px;
                opacity: 1;

                img {
                    opacity: 0;
                }
            }
        }
    }
}
