.u-link-bare {
    text-decoration: none !important;
}

.u-link-natural {
    text-decoration: underline !important;
    color: inherit !important;
}

.u-link-obvious {
    text-decoration: underline !important;
}
