@import url("https://use.typekit.net/ydf5cjr.css");

@import url("https://p.typekit.net/p.css?s=1&k=ydf5cjr&ht=tk&f=10954.13453.13454.13455&a=4336486&app=typekit&e=css");

@font-face {
    font-family: "freight-sans-pro";
    src: url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}


.tk-freight-sans-pro {
    font-family: "freight-sans-pro", sans-serif;
}

@font-face {
    font-family: 'nimbus_sans_round_black';
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Black.eot');
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Black.eot?#iefix') format('embedded-opentype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Black.woff') format('woff'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Black.ttf') format('truetype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Black.svg#nimbus_sans_round_black') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nimbus_sans_round_bold';
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Bold.eot');
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Bold.woff') format('woff'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Bold.ttf') format('truetype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Bold.svg#nimbus_sans_round_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nimbus_sans_round_medium';
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Medium.eot');
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Medium.woff') format('woff'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Medium.ttf') format('truetype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Medium.svg#nimbus_sans_round_medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nimbus_sans_round_regular';
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Regular.eot');
    src: url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Regular.woff') format('woff'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Regular.ttf') format('truetype'),
        url('assets/styles/fonts/nimbus_sans_round/NimbusSansRound-Regular.svg#nimbus_sans_round_regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
