// Color palette
$color-brand:           #191D25;
$color-brand1:          #333B4A;
$color-brand2:          #242934;
$color-brand3:          #B75922;


$color-brand-highlight: lighten($color-brand, 15%);
$color-brand-shadow:    darken($color-brand, 15%);
$color-brand3-highlight: lighten($color-brand3, 15%);
$color-brand3-shadow: darken($color-brand3, 15%);

$white:                 #fff;
$white-darken:          darken($white, 25%);

$black:                #000;

$color-gray:            #EBEBEB;
$color-gray-light:      #ccc;
$color-gray-dark:       #151515;

$color-highlight:       rgba(255, 255, 255, 0.05);
$color-shadow:          rgba(0, 0, 0, 0.05);

// UI
$color-ui:          $color-gray-light;

// Text
$color-text:            #242934;

// Links
$color-link:            $color-brand;
$color-link-hover:      $color-brand3;

// Buttons
$color-btn:             $color-brand;
$color-btn-hover:       $white;
