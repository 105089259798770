.book-a-table {
    display: none;
    transition: all 0.33 ease-in-out;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 92px;
    background: $color-brand;
    border-top: 2px solid $color-brand3;

    @include media-breakpoint-down(sm) {
        transition: all 0.33 ease-in-out;
        display:block;
    }

    .btn {
        width: 100%;
        height: 92px;
        line-height: 78px;
        border: 2px solid $color-brand;
        z-index: 0;

        &:hover,
        &:focus {
            border: 2px solid $color-brand3;

        }
    }
}

.o-page-header {
    transition: all 1.25s cubic-bezier(0.655, 0.04, 0, 1.005);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 300px;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    text-align: center;
    background: $color-brand;

    @include media-breakpoint-down(sm) {
        width: 100%;
        height: 64px;
        z-index: 999;
    }

    &.open {
        height: 100vh;
        overflow: scroll;

        .o-menu-wrapper {
            .nav--main {

                @include media-breakpoint-down(sm) {
                    margin-bottom: 6px;
                }

                .nav__links {
                    li {
                        a {
                            transition: all 0.33s ease-in-out;

                            &:hover {
                                transition: all 0.33s ease-in-out;
                            }
                        }
                    }
                }
            }
        }

        .book-table {
            @include media-breakpoint-down(sm) {
                height: auto;
            }
        }
    }

    .btn-menu-wrapper {
        position: absolute;
        display: none;
        width: 30px;
        height: 30px;
        left:18px;
        top:17px;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .menu-bars {
            position: relative;
            display: inline-block;
            width: 28px;
            height: 16px;

            @include media-breakpoint-down(md) {
                top: 3px;
            }

            &.open {
                .top {
                    transition: transform 0.35s ease-in-out;
                    transform: rotate(-45deg);
                    top: 6px;
                }

                .middle {
                    transition: opacity 0.05s;
                    opacity: 0;
                }

                .bottom {
                    transition: transform 0.35s ease-in-out;
                    transform: rotate(45deg);
                    bottom: 8px;
                }
            }

            .top,
            .middle,
            .bottom {
                transition: transform 0.35s ease-in-out;
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                background: $white;
            }

            .middle {
                transition: opacity 1.5s;
                top: calc(50% - 1px);
                opacity: 1;
            }

            .bottom {
                bottom: 0;
            }
        }
    }

    .link--logo {
        transition: all 0.33s ease-in-out;
        position: relative;
        top: 0;
        display: block;
        width: 116px;
        margin: 70px auto;

        @include media-breakpoint-down(md) {
            transition: all 0.33s ease-in-out;
            margin: 0 auto;
            width: 116px;
        }

        @include media-breakpoint-down(sm) {
            transition: all 0.33s ease-in-out;
            top: 8px;
            width: 46px;
            margin-bottom: 10px;
        }

        .link--logo--image {
            transition: all 0.33s ease-in-out;
            display: block;
            height: auto;
            width: 100%;
        }
    }

    .nav-phone {
        position: absolute;
        top: 17px;
        right: 18px;
        display: block;
        width: 22px;
        height: 22px;
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    .o-menu-wrapper {
        position: relative;
        transition: all 0.33s ease-in-out;
        display: block;
        width: 100%;
        text-align: center;
        font-family: 'nimbus_sans_round_bold';

        .nav--main {
            width: 100%;
            position: relative;
            //margin-bottom: 60px;

            .nav__links {
                list-style-type: none;
                width: 100%;
                margin: 0;
                padding: 0;

                li {
                    a {
                        transition: all 0.33s ease-in-out;
                        line-height: 44px;
                        font-family: 'nimbus_sans_round_bold';
                        font-size: 16px;
                        letter-spacing: 4px;
                        color: $white;
                        text-transform: uppercase;

                        &:hover {
                            transition: all 0.33s ease-in-out;
                            text-decoration: none;
                            color: $color-brand3;
                        }
                    }
                }
            }
        }
    }

    .o-social-nav {
        position: relative;

        .social-nav__links {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                font-size: 0;
                width: 22px;
                height: 22px;

                &.social {
                    margin: 0 7px 12px;

                    &.facebook {
                        a {
                            background: url('assets/images/FB-Icon.svg') top center no-repeat;

                            &:hover {
                                background: url('assets/images/FB-Icon-Gold.svg') top center no-repeat;
                            }
                        }
                    }

                    &.twitter {
                        a {
                            background: url('assets/images/TW-Icon.svg') center center no-repeat;

                            &:hover {
                                background: url('assets/images/TW-Icon-Gold.svg') center center no-repeat;
                            }
                        }
                    }

                    &.instagram {
                        a {
                            background: url('assets/images/IG-Icon.svg') center center no-repeat;

                            &:hover {
                                background: url('assets/images/IG-Icon-Gold.svg') center center no-repeat;
                            }
                        }
                    }

                    &.yelp {
                        a {
                            background: url('assets/images/Yelp-Icon.svg') center center no-repeat;

                            &:hover {
                                background: url('assets/images/Yelp-Icon-Gold.svg') center center no-repeat;
                            }
                        }
                    }

                    a {
                        transition: all 0.33s ease-in-out;
                        display: block;
                        width: 22px;
                        height: 22px;
                        background: url('assets/images/FB-Icon.svg') top center no-repeat;

                        &:hover {
                            transition: all 0.33s ease-in-out;
                            background: url('assets/images/FB-Icon-Gold.svg') top center no-repeat;
                        }
                    }
                }
            }
        }
    }

    .o-contact-info {
        position: relative;
        display: block;
        font-family: 'nimbus_sans_round_bold';
        font-size: 16px;
        letter-spacing: 4px;
        color: $white;

        a {
            transition: all 0.33s ease-in-out;
            color: $white;

            &:hover {
                transition: all 0.33s ease-in-out;
                text-decoration: none;
                color: $color-brand3;
            }
        }
    }

    .book-table {
        transition: all 0.33 ease-in-out;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 96px;
        background: $color-brand3;

        @include media-breakpoint-down(sm) {
            transition: all 0.33 ease-in-out;
            height: 0;
            bottom: 0;
        }

        .btn {
            width: 100%;
            height: 96px;
            line-height: 82px;
            border: 2px solid $color-brand3;
            z-index: 0;

            &:before {
                background-color: $white;
            }

            &:hover,
            &:focus {
                color: $color-brand3;
                border: 2px solid $color-brand3;

            }
        }
    }
}
